<template>
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Veterans Contact List
        </span>
        <!-- <span class="text-muted mt-3 font-weight-bold font-size-sm">
          More than 400+ new members
        </span> -->
      </h3>
      <div class="card-toolbar d-flex align-items-center">
        <div class="d-none">
          <div>
            <input
              type="text"
              class="form-control form-control-solid form-control-md"
              v-model="searchKeyword"
            />
          </div>
          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm ml-4 search_button"
            :class="{ disabled: searchKeyword.length < 3 }"
            @click="searchData"
          >
            Search</a
          >
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->

    <div class="card-body py-0">
      <!--begin::Preferences-->
      <form data-kt-search-element="preferences" class="pt-1">
        <!--begin::Heading-->
        <h4 class="fw-bold text-dark mb-7">Search Preferences</h4>
        <!--end::Search 23 sep-->
        <div class="row d-none">
          <div class="form-check form-switch col-3 mb-5 pb-5">
            <div class="d-flex justify-content-start align-items-center">
              <input
                class="form-control form-control-solid form-control-xs search-input"
                type="checkbox"
                id="searchFirstName"
              />
              <label class="form-check-label search-label" for="searchFirstName"
                >First name</label
              >
            </div>
          </div>
          <div class="form-check form-switch col-3 mb-5 pb-5">
            <div class="d-flex justify-content-start align-items-center">
              <input
                class="form-control form-control-solid form-control-xs search-input"
                type="checkbox"
                id="searchLastName"
              />
              <label class="form-check-label search-label" for="searchLastName"
                >Last name</label
              >
            </div>
          </div>
          <div class="form-check form-switch col-3 mb-5 pb-5">
            <div class="d-flex justify-content-start align-items-center">
              <input
                class="form-control form-control-solid form-control-xs search-input"
                type="checkbox"
                id="searchPhone"
              />
              <label class="form-check-label search-label" for="searchPhone"
                >Phone</label
              >
            </div>
          </div>
          <div class="form-check form-switch col-3 mb-5 pb-5">
            <div class="d-flex justify-content-start align-items-center">
              <input
                class="form-control form-control-solid form-control-xs search-input"
                type="checkbox"
                id="searchLocations"
              />
              <label class="form-check-label search-label" for="searchLocations"
                >Locations</label
              >
            </div>
          </div>
          <div class="form-check form-switch col-12 mb-5 pb-5">
            <div class="d-flex justify-content-start align-items-center">
              <input
                class="form-control form-control-solid form-control-xs search-input"
                type="checkbox"
                id="searchBranchofService"
              />
              <label
                class="form-check-label search-label"
                for="searchBranchofService"
                >Branch of service</label
              >
            </div>
            <div class="row">
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchBranchofServiceAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchBranchofServiceAll"
                    >All</label
                  >
                </div>
              </div>
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchBranchofServiceAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchBranchofServiceAll"
                    >All</label
                  >
                </div>
              </div>
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchBranchofServiceAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchBranchofServiceAll"
                    >All</label
                  >
                </div>
              </div>
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchBranchofServiceAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchBranchofServiceAll"
                    >All</label
                  >
                </div>
              </div>
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchBranchofServiceAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchBranchofServiceAll"
                    >All</label
                  >
                </div>
              </div>
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchBranchofServiceAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchBranchofServiceAll"
                    >All</label
                  >
                </div>
              </div>
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchBranchofServiceAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchBranchofServiceAll"
                    >All</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-check form-switch col-12 mb-5 pb-5">
            <div class="d-flex justify-content-start align-items-center">
              <input
                class="form-control form-control-solid form-control-xs search-input"
                type="checkbox"
                id="searchTypeofWar"
              />
              <label class="form-check-label search-label" for="searchTypeofWar"
                >Type of war</label
              >
            </div>
            <div class="row">
              <div class="col-3 mt-5">
                <div class="d-flex justify-content-start align-items-center">
                  <input
                    class="form-control form-control-solid form-control-xs search-input"
                    type="checkbox"
                    id="searchTypeofWarAll"
                  />
                  <label
                    class="form-check-label search-label"
                    for="searchTypeofWarAll"
                    >All</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Search-->
        <!--end::Search 24 sep-->
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>First Name :</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="firstName"
                v-model="searchFields[0].value"
              />
              <span class="form-text text-muted">Search with First Name.</span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Last Name :</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="Last Name"
                v-model="searchFields[1].value"
              />
              <span class="form-text text-muted">Search with Last Name.</span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Branch of Service :</label>
              <select
                name="Contact Owner"
                class="form-control form-control-solid form-control-lg"
                v-model="searchFields[2].value"
              >
                <option value="" selected disabled hidden>All</option>
                <option value="Air Force">Air Force</option>
                <option value="Army">Army</option>
                <option value="Coast Guard">Coast Guard</option>
                <option value="Marines">Marines</option>
                <option value="National Guard">National Guard</option>
                <option value="Navy">Navy</option>
                <option value="Space Force">Space Force</option>
                <option value="Unknown">Unknown</option>
              </select>
              <span class="form-text text-muted"
                >Search with branch of service.</span
              >
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Mobile Number :</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                v-model="searchFields[3].value"
              />
              <span class="form-text text-muted"
                >Search with Mobile Number.</span
              >
            </div>
          </div>
          <!-- <div class="col-xl-4">
            <div class="form-group">
              <label>Type of War :</label>
              <select
                name="Contact Owner"
                class="form-control form-control-solid form-control-lg"
                v-model="searchFields[5].value"
              >
                <option value="" selected disabled hidden>All</option>
                <option value="Air Force">Air Force</option>
                <option value="Army">Army</option>
                <option value="Coast Guard">Coast Guard</option>
                <option value="Marines">Marines</option>
                <option value="National Guard">National Guard</option>
                <option value="Navy">Navy</option>
                <option value="Space Force">Space Force</option>
              </select>
              <span class="form-text text-muted"
                >Search with branch of service.</span
              >
            </div>
          </div> -->
          <div class="col-xl-4">
            <div class="form-group">
              <label>Location :</label>
              <select
                name="Contact Owner"
                class="form-control form-control-solid form-control-lg"
                v-model="searchFields[4].value"
              >
                <option value="" selected disabled hidden>All</option>
                <option value="St. Thomas">St. Thomas</option>
                <option value="St Croix">St Croix</option>
                <option value="St. John">St. John</option>
              </select>
              <span class="form-text text-muted">Search with location.</span>
            </div>
          </div>
          <div class="col-xl-12 mb-5">
            <a
              href="#"
              class="btn btn-block btn-primary font-weight-bolder font-size-sm search_button"
              @click="searchData"
            >
              Search</a
            >
          </div>
        </div>
      </form>
      <!--end::Preferences-->
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr class="text-left">
              <!--<th class="pl-0" style="width: 20px">
                <label class="checkbox checkbox-lg checkbox-single">
                  <input
                    type="checkbox"
                    @input="setCheck($event.target.checked)"
                  />
                  <span></span>
                </label>
              </th>-->
              <th class="pr-0" style="max-width: 200px">First Name</th>
              <th class="pr-0" style="max-width: 200px">Last Name</th>
              <th style="max-width: 200px">Branch of Service</th>
              <!-- <th style="max-width: 150px">Gender</th> -->
              <th style="max-width: 150px; text-align: right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <a
                    href="#"
                    @click="editDetail(item.userId)"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ item.firstName }}</a
                  >
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.lastName }}</span
                  >
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.branchOfService }}</span
                  >
                </td>

                <td class="pr-0 text-right">
                  <span
                    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    @click="editDetail(item.userId)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span
                    href="#"
                    class="btn btn-icon btn-light btn-hover-danger btn-sm"
                    @click="deleteUser(item.userId)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </td>
              </tr>
            </template>
            <template v-if="list.length === 0">
              <tr>
                <td class="text-center" colspan="4">No record found</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_VETERANS_LIST,
  DELETE_VETERAN,
  SEARCH_VETERANS_LIST,
} from "@/core/services/store/veterans.module";
import Swal from "sweetalert2";

export default {
  name: "SearchResult",
  components: {},
  data() {
    return {
      searchKeyword: "",
      list: [],
      searchFields: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Search Result" }]);
    // this.getVeteransList();
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    async getVeteransList() {
      try {
        const response = await this.$store.dispatch(GET_VETERANS_LIST, {
          limit: "",
          nextPage: "",
        });
        if (response?.success) {
          this.list = response.result.result;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async editDetail(userId) {
      // this.$router.push({ name: "contactDetail", params: { id: userId } });
      this.$router.push(`/contact-detail/${userId}`);
    },
    async deleteUser(userId) {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.$store.dispatch(DELETE_VETERAN, userId);
            if (response) {
              Swal.fire({
                title: "",
                text: "User has been successfully deleted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              this.list = this.list.filter((l) => l.userId !== userId);
            }
          } catch (err) {
            console.error(err);
          }
        }
      });
    },
    async searchData() {
      const searchParams = [];
      this.searchFields.map((sf, ix) => {
        if (sf.value) {
          searchParams.push({ field: ix + 1, value: sf.value });
        }
      });
      if (searchParams.length === 0) {
        this.getVeteransList();
      } else {
        try {
          const response = await this.$store.dispatch(
            SEARCH_VETERANS_LIST,
            searchParams
          );
          if (response) {
            this.list = response;
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
  },
};
</script>
<style scoped>
.search_button.disabled {
  pointer-events: none;
}
.search-input {
  width: 24px;
  height: 24px;
  margin-right: 0.75rem;
}
.search-label {
  font-weight: 500;
}
</style>
